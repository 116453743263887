.centrato-verticale-div-sign-up {
    margin: 0 auto;
    width: 600px;
    text-align: center;
    margin-top: 10%;
}

.full-width {
    width: 100%;
}

.testo-tally-bold-nero {
    text-align: left;
    margin-bottom: 0;
    font-weight: bold;
}

.sign-up-button {
    width: 100%;
    height: 45px;
}

.sign-up-titolo {
    font-weight: bold;
    text-decoration: underline;
    color: #FF00D8;
    font-size: 22px;
    text-align: left;
}

.sign-up-condition-tally {
    margin-top: 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2em;
}

.sign-up-link-tally {
    color: #FF00D8;
}

.sign-up-error-messagge {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.2em;
    text-align: left;
}

.sign-up-success-messagge {
    border: 1px solid grey;
    padding: 10px;
    border-radius: 10px;
    color: limegreen;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.2em;
    text-align: left;
}

@media screen and (max-width: 600px) {
    .body-mobile{
        min-width: 100% !important;
    }
    .centrato-verticale-div-sign-up {
        max-width: 600px !important;
        padding: 25px;
        width: 100%;
        text-align: center;
        margin-top: 2%;
    }
}