/*GENERAL 1234*/

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:ital,wght@0,300;0,400;0,700;1,400&display=swap");
body {
  padding-top: 1%;
  padding-left: 6%;
  padding-right: 6%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Be Vietnam", sans-serif !important;
  max-width: 2400px;
  margin: 0 auto !important;
  min-width: 1100px !important;
  overflow: auto;
}

#root {
  margin: 0 auto !important;
}

a:hover {
  text-decoration: none !important;
  cursor: pointer;
}

::placeholder {
  color: rgba(0, 0, 0, 0.42);
  font-weight: 300;
}

path {
  stroke: #f0f0f0;
}

button:focus {
  outline: 0px !important;
}

input {
  border: 0;
  font-weight: 400;
  border-bottom: 1px solid #ff00d8;
}

input:focus {
  border: 0;
  border-bottom: 1px solid #707070;
  outline: none !important;
}

input:disabled {
  background-color: #f8f8f8 !important;
  cursor: not-allowed;
}

.container-input-quadri {
  border-bottom: 1px solid #ff00d8;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.container-input-quadri b {
  margin-bottom: 0;
  text-decoration: underline;
}

.titolo-pagina {
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.2em;
  color: #ff00d8;
}

.titolo-sezione-createtag {
  font-size: 1em;
  text-decoration: underline;
  font-weight: bold;
}

.div-popover-tally {
  position: relative;
}

.popover-tally {
  padding: 15px;
  position: absolute;
  align-items: center;
  background-color: white;
  height: 40px;
  color: black;
  width: auto;
  line-height: 1em;
  min-width: 100px;
  border: 1px solid #ff00d8;
  text-align: center;
  vertical-align: middle;
  border-radius: 15px;
  top: 5px;
  left: 65px;
  display: none;
}

.div-popover-tally:hover .popover-tally {
  display: -webkit-flex;
  display: flex;
}

.titolo-sezione-createtag span {
  color: #ff00d8;
}

.tally-color-text {
  color: #ff00d8;
}

.nopadding-support-ticket {
  padding: 0;
  margin: 0 !important;
  padding-left: 8px !important;
}

.tally-router {
  min-height: 500px;
}

.page-tally {
  min-height: 600px;
}

.tally-dashboard {
  min-height: 80%;
}

.tally-grey-text {
  color: #747474;
  line-height: 1.2em;
}

.paddingTopPageTally {
  margin-top: 20px;
}

.paragrafo-default-tally {
  line-height: 1em;
  margin-bottom: 0.4em;
}

.div-selezione-tipologia-createtag {
  text-align: center;
  border: 1px solid #ff00d8;
  border-radius: 20px;
}

.textarea-slum {
  width: 100%;
  -webkit-box-shadow: 0px 2px 0px 0px rgba(255, 0, 216, 1);
  -moz-box-shadow: 0px 2px 0px 0px rgba(255, 0, 216, 1);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 216, 1);
  border-bottom: 0 0 0 0.1rem #ff00d8;
}

textarea:focus {
  border: 0;
  box-shadow: 0;
}

.textarea-slum:focus {
  outline: 0;
  border-bottom: 1px solid black;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1);
}

label {
  padding: 10px;
  display: table;
  color: #fff;
}

input[type="file"] {
  display: none;
}

.cella-time {
  padding: 0px !important;
  margin-right: 10px;
  opacity: 1;
  background-color: #f2f2f2;
  height: 22px;
}

.cella-time-viola {
  height: 100% !important;
  background-color: #ff00d8 !important;
  width: 100% !important;
}

.disabled-image-input {
  background-color: #f8f8f8 !important;
  cursor: not-allowed;
}

.custom-select:disabled {
  background-color: #f8f8f8 !important;
  cursor: not-allowed;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  .view-tag-row {
    height: 85px !important;
    padding-bottom: 0px !important;
  }
}

.view-tag-row {
  padding-bottom: 75px;
  margin-bottom: 0px;
}

.btn-radius-choose-template {
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #818181;
}

.btn-radius-choose-template:hover {
  background-color: #818181;
  color: white;
}

.btn-radius-choose-template-active {
  background-color: #818181;
  color: white;
  margin-top: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #818181;
}

.link-tally {
  color: #ff00d8;
  text-decoration: underline;
  font-weight: 300;
  font-size: 0.9em;
}

.link-tally:hover {
  color: #ff00d8;
  text-decoration: underline;
}

.titolo {
  margin-right: 0%;
  margin-left: 0% !important;
  word-break: break-word;
  margin-bottom: 0px;
}

.titolo-tally {
  font-weight: bold;
  margin-bottom: 0px;
}

.descrizione-tally {
  font-weight: 300;
  margin-bottom: 0px;
}

.title-preview {
  color: white;
  font-size: 1.2em;
  word-wrap: break-word;
  font-weight: bold;
}

.preview-template-div {
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

.mobile-background-preview {
  text-align: center;
  background: #191919 0% 0% no-repeat padding-box;
  padding-left: 13px;
  padding-top: 5px;
  border-radius: 15px;
  width: 80%;
  height: 100%;
  margin: 0 auto;
}

.mobile-background-preview-active {
  text-align: center;
  background: #191919 0% 0% no-repeat padding-box;
  padding-left: 13px;
  padding-top: 5px;
  border-radius: 15px;
  width: 80%;
  height: 100%;
  margin: 0 auto;
}

.mobile-background {
  background: #191919 0% 0% no-repeat padding-box;
  padding-left: 13px;
  padding-top: 15px;
  border-radius: 30px;
  max-width: 400px;
  width: 100%;
  max-width: 414px;
  height: 740px;
  zoom: 80%;
}

.mobile-background-fixed {
  position: fixed;
  top: 480px;
  background: #191919 0% 0% no-repeat padding-box;
  padding-left: 13px;
  padding-top: 15px;
  border-radius: 30px;
  width: 400px;
  height: 740px;
  zoom: 80%;
}

.testoCitazioneLoader {
  font-weight: 300;
  color: #ff00d8;
  line-height: 0.8em;
  text-align: left;
  margin-left: 43%;
}

.sottotesto-loader {
  font-weight: 300;
  margin-top: 5px;
}

.preview-device {
  max-width: 414px;
  width: 97%;
  background-color: white;
  border-radius: 20px !important;
  height: 667px;
  overflow-y: scroll;
}

.listaChooseTemplate {
  margin-top: 12%;
}

.listaChooseTemplate:hover {
  cursor: pointer;
}

.listaChooseTemplate li {
  margin-top: 1.5%;
  font-weight: 500;
  font-size: 1em;
}

.listaChooseTemplate li:hover {
  text-decoration: underline;
}

.liselected {
  text-decoration: underline;
  font-weight: bold !important;
  font-size: 1.2em;
}

.button-success-tally {
  background-color: #10d300;
  border: 1px solid #10d300;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.button-success-tally:hover {
  background-color: #10d300;
  border: 1px solid #10d300;
  color: white;
}

.button-success-tally:disabled {
  background-color: #f8f8f8;
  border: 1px solid lightgrey;
  color: black;
  cursor: not-allowed;
}

.button-alert-tally {
  background-color: red;
  border: 1px solid red;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.swal-button--confirm {
  background-color: #10d300;
  border: 1px solid #10d300;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.swal-button--confirm:hover {
  background-color: #10d300 !important;
  border: 1px solid #10d300;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.swal-button--confirm:focus {
  box-shadow: none !important;
}

.swal-button--cancel {
  background-color: red;
  border: 1px solid red;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.swal-button--cancel:hover {
  background-color: red !important;
  border: 1px solid red;
  color: white;
}

.swal-modal {
  border-radius: 35px !important;
}

.tally-active-icon {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #10d300;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.tally-deactive-icon {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: red;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.button-alert-tally:hover {
  background-color: red;
  border: 1px solid red;
  color: white;
}

.button-danger-tally {
  background-color: #ff8c00;
  border: 1px solid #ff8c00;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.button-danger-tally:hover {
  background-color: #ff8c00;
  border: 1px solid #ff8c00;
  color: white;
}

.custom-select {
  min-width: 100%;
  text-align: center;
  border-radius: 0 !important;
  text-align-last: center;
  border: 1px solid black !important;
}

.custom-select:focus {
  border-color: transparent !important;
  outline: 0;
  border-radius: 0;
  box-shadow: 0 0 0 0.01rem !important;
  border: 1px solid black !important;
}

.custom-select select {
  display: none;
  /*hide original SELECT element: */
}

.switchToggle {
  margin-top: 15px;
}

.switchToggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  max-width: 70px;
  height: 32px;
  background: #d1d1d1;
  display: block;
  border-radius: 100px;
  zoom: 0.7;
  position: relative;
}

.switchToggle label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchToggle input:checked + label,
.switchToggle input:checked + input + label {
  background: #10d300;
}

.switchToggle input + label:before,
.switchToggle input + input + label:before {
  content: "No";
  position: absolute;
  top: 5px;
  left: 35px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked + label:before,
.switchToggle input:checked + input + label:before {
  content: "Si";
  position: absolute;
  top: 5px;
  left: 10px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked + label:after,
.switchToggle input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchToggle label:active:after {
  width: 60px;
}

.toggle-switchArea {
  margin: 10px 0 10px 0;
}

.select-selected {
  background-color: DodgerBlue;
}

.btn-tally-grey {
  background-color: white;
  border: 1px solid #818181;
  color: #818181;
  border-radius: 25px;
  box-shadow: 0;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn-tally-grey:hover {
  background-color: #818181;
  border: 1px solid #818181;
  color: white;
  border-radius: 25px;
  box-shadow: 0;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn-tally-move {
  margin-top: 15px;
  background-color: white;
  border: 1px solid #818181;
  color: #818181;
  border-radius: 25px;
  box-shadow: 0;
  width: 45%;
  margin-right: 5%;
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn-tally-move:hover {
  margin-top: 15px;
  background-color: #818181;
  border: 1px solid #818181;
  color: white;
  border-radius: 25px;
  box-shadow: 0;
  width: 45%;
  margin-right: 5%;
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn-tally-move-custom {
  display: inline;
  font-size: 1.5em;
  border: 0;
  background-color: white;
}

.custom-category {
  display: inline-block;
  float: right;
}

select {
  cursor: pointer;
}

.istruzioni-modale-modifica {
  color: rgb(144, 144, 144);
}

.margin-15 {
  margin-top: 15px;
  overflow-y: scroll;
}

.overflow-prodotti {
  max-height: 450px;
  overflow: scroll;
}

.titolo-alto {
  font-size: 1em;
  font-weight: bold;
  color: #ff00d8;
  line-height: 1em;
}

.titolo-alto-create-template {
  font-size: 1.1em;
  font-weight: bold;
  color: black;
  line-height: 1em;
}

.ul-selezione {
  margin-top: 25px;
}

.btn-next-step {
  border: 1px solid #818181;
  opacity: 1;
  background-color: white;
  font-weight: 300;
  height: 8%;
  width: 50%;
}

.btn-next-step:hover {
  opacity: 1;
  color: white;
  background-color: #ff00d8;
  border: 1px solid #ff00d8;
  font-weight: 300;
  height: 8%;
  width: 50%;
}

select {
  background-color: white;
  width: 100%;
  border: 1px solid #818181;
}

li {
  list-style: none;
}

ul {
  padding-left: 20px;
}

.ul-dashboard li {
  list-style: disc;
}

.page-tally {
  margin: 0 auto;
  margin-bottom: 3% !important;
}

.tally-title {
  font: Bold 25px/37px Be Vietnam;
  letter-spacing: 0;
  color: #747474;
}

.sotto-tally-title {
  font: Bold 18px/27px Be Vietnam;
  letter-spacing: 0;
  color: #747474;
}

.tally-text {
  font: Light 18px Be Vietnam;
  letter-spacing: 0;
  color: #747474;
}

/*Image fit*/

.container_img {
  width: 100%;
  height: 115px;
  line-height: 115px;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
}

.resize_fit_center_img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: unset;
  overflow-y: hidden !important;
}

.btn-visual {
  text-align: left;
  color: #a5a5a5;
  width: 100%;
  margin-top: -50px;
  margin-bottom: 20px;
}

.btn-visual:hover {
  text-align: left;
  color: #a5a5a5;
  font-weight: bold;
  text-decoration: underline;
  width: 100%;
  margin-top: -50px;
  margin-bottom: 20px;
}

.pallino-active {
  width: 22px !important;
  height: 22px !important;
  display: inline-flex;
  vertical-align: middle;
  background-color: #ff00d8;
  margin-right: 10px;
  border-radius: 50%;
}

.btn-visual-active {
  text-align: left;
  color: #ff00d8;
  font-weight: bold;
  text-decoration: underline;
  width: 100%;
  margin-top: -50px;
  margin-bottom: 20px;
}

.whatsapp-button {
  border: 1px solid #10d300;
  background-color: white;
  border-radius: 30px;
  width: 100%;
  height: 35px;
  color: black;
}

.whatsapp-button:hover {
  border: 1px solid #10d300;
  background-color: #10d300;
  border-radius: 30px;
  width: 100%;
  height: 35px;
  color: white !important;
}

.whatsapp-button a {
  color: black;
}

.whatsapp-button a:hover {
  color: white;
  text-decoration: none;
}

.resetImmagineProfilo .container_img {
  max-width: 100%;
  overflow: hidden;
}

.pallino-inattivo {
  width: 22px !important;
  height: 22px !important;
  display: inline-flex;
  vertical-align: middle;
  background-color: #a5a5a5;
  margin-right: 10px;
  border-radius: 50%;
}

.sceltamenu-active {
  margin-bottom: 15px;
  border: 1px solid #ff00d8;
  padding: 7px;
  text-align: center;
  background-color: #ff00d8;
  color: white !important;
}

.sceltamenu-active a {
  color: white;
  cursor: default;
}

.sceltamenu-active a:hover {
  color: white;
  text-decoration: none;
}

.sceltamenu {
  margin-bottom: 15px;
  border: 1px solid #818181;
  padding: 7px;
  text-align: center;
  background-color: white;
  color: #818181 !important;
}

.sceltamenu a {
  color: black;
}

.sceltamenu:hover {
  margin-bottom: 15px;
  border: 1px solid #ff00d8;
  padding: 7px;
  text-align: center;
  background-color: #ff00d8;
  color: white !important;
}

.sceltamenu:hover a {
  color: white;
}

.logo-p-switch {
  width: 45px;
  margin-left: 10px;
  display: inline;
}

.seleziona-categoria-freccia {
  margin-bottom: 15px;
  border: 1px solid #818181;
  padding: 7px;
  text-align: center;
  background-color: white;
  color: #818181 !important;
  display: inline-block;
  max-width: 80% !important;
  min-width: 75% !important;
}

.seleziona-categoria-freccia:hover {
  margin-bottom: 15px;
  border: 1px solid #ff00d8;
  padding: 7px;
  text-align: center;
  background-color: #ff00d8;
  color: white !important;
}

.seleziona-categoria-freccia:hover a {
  color: white;
}

.seleziona-categoria-freccia-active {
  margin-bottom: 15px;
  border: 1px solid #ff00d8;
  padding: 7px;
  text-align: center;
  background-color: #ff00d8;
  color: white !important;
  display: inline-block;
  max-width: 80% !important;
  min-width: 75% !important;
}

.seleziona-categoria-freccia-active a {
  color: white;
}

.seleziona-categoria-freccia a {
  color: black;
}

.nav-bar-right {
  position: absolute;
  bottom: 20%;
  left: 35px;
}

.nav-bar-right img {
  margin-bottom: 25px;
  background-color: white;
}

.nav-bar-right img:hover {
  border-radius: 50%;
}

.btn-visual-save {
  border: 1px solid #10d300;
  color: #10d300;
  background-color: white;
  border-radius: 30px;
  width: 100%;
  height: 35px;
}

.btn-visual-save:hover {
  background-color: #10d300;
  color: white;
  border-radius: 30px;
  width: 100%;
  height: 35px;
}

.padding-view-tag {
  display: flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
  word-break: breal;
  align-self: center;
  justify-content: center;
  line-height: 1em;
}

.btn-tally-show-tag {
  box-shadow: 0;
  border: 1px solid #818181;
  width: 100%;
  z-index: 20;
  border-radius: 30px;
  font-weight: bold;
  font-size: 1em;
  height: 35px;
  margin-bottom: 20px;
  background-color: white;
}

.btn-tally-show-tag-dati {
  box-shadow: 0;
  border: 1px solid #818181;
  z-index: 20;
  width: 100%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 1em;
  height: 35px;
  margin-bottom: 10px;
  background-color: white;
}

.modal-header {
  border-bottom: 1px solid #ff00d8 !important;
}

.tally-p a {
  color: #ff00d8;
  text-decoration: underline;
}

.tally-p a:hover {
  color: #ff00d8;
  text-decoration: none;
}

.modal-content {
  border: 0;
  border-radius: 20px !important;
}

.span-center {
  text-align: center;
}

.span-grid {
  text-align: center;
  text-decoration: underline;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.btn-tally-show-tag:hover {
  box-shadow: 0;
  opacity: 1;
  background-color: #10d300;
  color: white;
  border: 0px;
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
}

.btn-tally-show-tag-dati:hover {
  box-shadow: 0;
  opacity: 1;
  background-color: #ff00d8;
  color: white;
  border: 0px;
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
}

.btn-tally-show-tag-grey {
  box-shadow: 0;
  border: 1px solid #818181;
  opacity: 1;
  width: 100%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 1em;
  height: 35px;
  margin-bottom: 20px;
  background-color: white;
}

.btn-tally-show-tag-grey:hover {
  box-shadow: 0;
  opacity: 1;
  background-color: #818181;
  color: white;
  border: 0px;
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
}

.btn-order-tag {
  box-shadow: 0;
  border: 1px solid #818181;
  opacity: 0.9;
  width: 45%;
  margin-left: 2%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 0.9em;
  height: 30px;
  margin-bottom: 20px;
  background-color: white;
}

.btn-order-tag:hover {
  box-shadow: 0;
  border: 1px solid #ff00d8;
  opacity: 0.9;
  width: 45%;
  margin-left: 2%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 0.9em;
  height: 30px;
  color: white;
  margin-bottom: 20px;
  background-color: #ff00d8;
}

.btn-order-tag-active {
  box-shadow: 0;
  border: 1px solid #ff00d8;
  opacity: 0.9;
  width: 45%;
  margin-left: 2%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 0.9em;
  height: 30px;
  color: white;
  margin-bottom: 20px;
  background-color: #ff00d8;
}

.box-tally {
  border: 1px solid #707070;
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 20px;
}

.btn-tally-general {
  border: 1px solid #ff00d8;
  color: black;
  font-size: 1em !important;
  background-color: white;
  border-radius: 50px;
  padding: 7px;
  margin-right: 5%;
  width: 20%;
  font-size: 0.9em;
}

.btn-tally-general-active {
  font-size: 1em !important;
  border: 1px solid #ff00d8;
  border-radius: 50px;
  padding: 7px;
  margin-right: 5%;
  width: 20%;
  font-size: 0.9em;
  color: white;
  background-color: #ff00d8;
}

.btn-tally-general-green {
  font-size: 1em !important;
  border: 1px solid #10d300;
  color: black;
  background-color: white;
  border-radius: 50px;
  padding: 7px;
  margin-right: 5%;
  width: 20%;
  max-width: 100%;
  font-size: 0.9em;
}

.btn-tally-general-green-active {
  font-size: 1em !important;
  border: 1px solid #10d300;
  border-radius: 50px;
  padding: 7px;
  margin-right: 5%;
  width: 20%;
  font-size: 0.9em;
  color: white;
  background-color: #10d300;
}

.btn-tally-general-green:hover {
  border: 1px solid #10d300;
  color: white;
  background-color: #10d300;
}

.btn-tally-general:hover {
  color: white;
  background-color: #ff00d8;
}

.input-create-template {
  width: 100%;
}

.margin-inside-box {
  margin-left: 10px;
  width: 80%;
}

.title-input {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: -2px !important;
}

/* Facebook fix */

.public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: #aaaaaa;
}

/* width */

::-webkit-scrollbar {
  width: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 60px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 30px;
}

.border-div-tag {
  border: 1px solid #707070;
  border-radius: 20px;
  margin-bottom: 7%;
}

.status-green {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(16, 211, 0);
  display: inline-flex;
}

.border-div-tag-inside {
  margin: 8%;
}

.border-div-tag-inside p {
  line-height: 1em;
  padding-right: 5% !important;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}

.margin-top-tag {
  margin-top: 3%;
}

.btn-tally-tag {
  border: 1px solid #10d300;
  background-color: white;
  border-radius: 30px;
  width: 100%;
  height: 35px;
}

.btn-tally-tag:hover {
  border: 1px solid #10d300;
  background-color: #10d300;
  color: white;
  border-radius: 30px;
  width: 100%;
  height: 35px;
}

/*HEADER*/

header {
  margin-top: 0px;
}

.myimg {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-top: -15px;
  border-radius: 50%;
}

.informazioni-header {
  margin-top: 0%;
  line-height: 1.1em;
  padding-left: 35px;
}

.bold {
  font-weight: bold;
}

.link-header:hover {
  color: #ff00d8 !important;
}

footer {
  padding-bottom: 15px;
}

.footer-content {
  padding-top: 15px;
  font-size: 14px;
}

.footer-content-center {
  font-size: 14px;
}

.footer-content-right {
  color: black !important;
  font-size: 14px;
}

.link-a {
  margin-right: 10px;
  color: black;
  font-size: 0.9em;
}

/*Giovannino*/

.row-template {
  padding-top: 5%;
}

.image-over {
  opacity: 0.1;
  z-index: 1;
}

.title-over {
  color: white;
  display: block;
  position: relative;
  text-align: center;
}

.overlay_old {
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 85%;
  transition: 0.2s ease;
  opacity: 0.8;
  background-color: white;
}

.modal-backdrop {
  opacity: 0.7 !important;
  background-color: white !important;
}

.overlay-active {
  display: none;
}

.text-not {
  display: none;
}

.text {
  color: black;
  font-size: 1em;
  font-weight: bold;
  line-height: 1em;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.preview-view-btn {
  text-decoration: underline;
  font-size: 1.4em;
  font-weight: 300;
  text-align: center;
  color: #ff00d8;
  cursor: pointer;
  line-height: 1.4em;
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0.8;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0.8;
  }
}

.footer-preview {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.slum-footer {
  font-weight: bold;
}

.modal-title-preview {
  font-weight: bold;
  font: Bold 25pt/37pt Be Vietnam;
  color: #222222;
}

.topnav-preview .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav-preview button:not(:first-child) {
    display: none;
  }
  .topnav-preview a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 1500px) and (min-width: 800px) {
  .btn-tally-show-tag {
    font-size: 0.8em;
  }
  .btn-tally-show-tag-dati {
    font-size: 0.8em;
  }
  .btn-tally-show-tag-grey {
    font-size: 0.8em;
  }
  .btn-tally-show-tag-grey:hover {
    font-size: 0.8em;
  }
  .btn-tally-show-tag:hover {
    font-size: 0.8em;
  }
  .btn-tally-show-tag-dati:hover {
    font-size: 0.8em;
    color: white;
  }
}

@media screen and (max-width: 1500px) {
  body {
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .topnav-preview .responsive {
    position: relative;
  }
  .topnav-preview .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav-preview .responsive button {
    float: none;
    display: block;
    text-align: left;
  }
}

.modal-preview {
  padding: 15px;
}

.red {
  color: red;
}

.check-error {
  color: red;
  font-size: 0.8em;
}

/* PAGE SUPPORTO */

.btn-tally-supporto {
  box-shadow: 0;
  border: 1px solid #ff00d8;
  opacity: 0.9;
  width: 100%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 1em;
  height: 45px;
  margin-bottom: 20px;
  background-color: white;
}

.btn-tally-supporto:hover {
  box-shadow: 0;
  opacity: 1;
  background-color: #ff00d8;
  color: white;
  border: 0px;
  width: 100%;
  margin-bottom: 20px;
}

.form-control-descrizione {
  height: 200px !important;
  margin-top: 3px;
}

.form-control:focus {
  outline: 0 !important;
}

.form-control-oggetto {
  margin-bottom: 15px;
  margin-top: 3px;
}

.btn-tally-supporto-chat {
  box-shadow: 0;
  border: 1px solid #10d300;
  opacity: 0.9;
  width: 100%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 1em;
  height: 45px;
  margin-bottom: 20px;
  background-color: white;
}

.viewtag-pagination-button {
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.viewtag-numero-pagina:hover {
  cursor: default !important;
  font-weight: 400 !important;
  text-decoration: none !important;
}

.viewtag-numero-pagina {
  cursor: none;
  color: black !important;
}

.viewtag-pagination-button span {
  cursor: pointer;
  color: #ff00d8;
}

.viewtag-pagination-button span:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #ff00d8;
  font-weight: bold;
}

.btn-tally-supporto-chat:hover {
  box-shadow: 0;
  opacity: 1;
  background-color: #10d300;
  color: white;
  border: 0px;
  width: 100%;
  margin-bottom: 20px;
}

.btn-tally-supporto-chat-invia {
  box-shadow: 0;
  opacity: 1;
  background-color: #10d300;
  color: white;
  border: 0px;
  width: 90%;
  height: 35px;
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 30px;
}

.ticket {
  padding: 0 !important;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  line-height: 1.2em;
}

.ticket-div {
  padding: 15px;
  padding-top: 0 !important;
  border-bottom: 1px solid rgb(112, 112, 112);
  padding-bottom: 0 !important;
}

.ticket-div:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.ticket-div-active {
  padding: 15px;
  padding-top: 0 !important;
  border-bottom: 1px solid rgb(112, 112, 112);
  background-color: #f0f0f0;
  cursor: pointer;
  padding-bottom: 0 !important;
}

.data_support {
  margin-bottom: 5px;
  margin-left: 8px;
  font-size: 0.8em !important;
}

.send-message {
  padding: 25px;
  border-radius: 20px 0px 20px 20px;
  background-color: #f0f8ff;
}

#box-tally-reset div .ticket {
  border-bottom: 0px !important;
}

.btn-send-message {
  border-radius: 50%;
  box-shadow: 0px;
  border: 0;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #10d300;
  margin-left: -15px;
}

.btn-send-message:hover {
  background-color: #10d300;
  border: 0px;
  color: white;
}

.received-message {
  padding: 25px;
  border-radius: 0px 20px 20px 20px;
  background-color: #f0f0f0;
}

.row-received-message {
  margin-top: 15px;
  width: 100%;
  padding-left: 35px;
  padding-top: 5px;
}

.support-chat {
  margin-left: 5%;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  padding: 25px;
  padding-bottom: 10px;
}

.content-support-chat {
  border-bottom: 0px;
  border-radius: 5px;
  overflow-y: scroll;
  width: 100%;
  height: 400px;
  padding-bottom: 15px;
}

.text-area-support-chat {
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 0px;
  width: 90%;
  height: 330px;
  margin-top: 20px;
}

.support-ticket {
  display: inline-block;
  text-align: left;
  padding: 0;
  margin-left: 5px;
  padding-top: 6px;
}

.support-ticket-stato-2 {
  position: relative;
  float: left;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(16, 211, 0);
}

.support-ticket-stato-1 {
  position: relative;
  float: left;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: orange;
}

.support-ticket-stato-0 {
  position: relative;
  float: left;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}

.titolo-pagina-div-support {
  margin-top: 40px;
}

.text-area-support-chat-div {
  margin-top: 15px;
}

.modal-content-support-form {
  border: 1px solid #ff00d8 !important;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px !important;
}

.modal-dialog-support-form {
  max-width: 900px !important;
}
.modal-crop .modal-content {
  margin: 0 auto;
}
.modal-crop .modal-dialog-support-form {
  max-width: 1300px !important;
}

.modal-body-support-form {
  padding-bottom: 50px !important;
  padding-top: 30px !important;
}

.close-support-form {
  cursor: pointer !important;
}

.input-support-form {
  width: 100% !important;
  margin-bottom: 18px !important;
}

.textarea-slum-support-form {
  border: 0px;
}

.error-support-form {
  text-align: center;
}

.error-img-support-form {
  margin: 0 auto;
  width: 50%;
  margin-bottom: 15px;
}

.scroll-support {
  height: 380px;
  overflow-y: scroll;
  margin-bottom: 15px;
  width: 100%;
}

.scroll-support-2 {
  overflow-y: scroll;
  width: 100%;
  height: auto;
}

.support-form-btn-invia {
  margin-top: 10px !important;
}

.support-form-btn-close {
  margin-top: 10px;
}

.support-form-btn-close-error {
  margin-top: 10px;
  width: 65%;
}

.support-form-btn-close-error:hover {
  width: 65% !important;
}

.content-supporto {
  padding-bottom: 35px;
}

.div-ticket-support {
  border: 1px solid rgb(112, 112, 112);
  border-radius: 20px;
  width: 100%;
  height: 524px;
  padding-bottom: 15px;
  padding-top: 30px;
  margin-left: 10% !important;
}

.div-ticket-support-2 {
  border: 1px solid rgb(112, 112, 112);
  border-radius: 20px;
  width: 100%;
  min-height: 524px;
  height: 100%;
  padding-bottom: 15px;
  padding-top: 30px;
  margin-left: 15px;
}

.div-ticket-support-3 {
  border: 1px solid rgb(112, 112, 112);
  border-radius: 20px;
  width: 100%;
  min-height: 524px;
  height: 100%;
  padding-bottom: 15px;
  padding-top: 30px;
  padding-right: 5%;
}

.titolo-alto-ticket-support {
  font-size: 1em;
  font-weight: bold;
  color: #ff00d8;
  line-height: 1em;
  margin-left: 5px;
}

.faqs-support-ticket {
  margin-bottom: 10px;
  padding-left: 8px !important;
}

.content-faqs-support {
  margin-top: 0 !important;
  width: 80%;
  border-bottom: 1px solid rgb(112, 112, 112);
}

.collapse-support {
  padding: 0 !important;
  margin: 0 !important;
}

.content-faqs-support-collapse {
  margin-top: 5px !important;
  width: 90%;
  border-bottom: 1px solid rgb(112, 112, 112);
}

.content-collapse-support {
  border: none !important;
  padding: 5px !important;
}

.collapse-titolo-alto-ticket-support {
  font-size: 1em;
  font-weight: bold;
  color: #ff00d8;
  line-height: 1em;
}

.btn-tally-supporto-invia-ticket {
  box-shadow: 0;
  border: 1px solid rgb(16, 211, 0);
  opacity: 0.9;
  width: 90%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 1em;
  height: 45px;
  margin-bottom: 20px;
  background-color: white;
  margin-left: 18px;
  margin-top: 6px;
}

.btn-tally-supporto-invia-ticket:hover {
  box-shadow: 0;
  opacity: 1;
  background-color: rgb(16, 211, 0);
  color: white;
  border: 0px;
  width: 90%;
  margin-bottom: 20px;
}

.header-modale-chat {
  padding-left: 40px !important;
  padding-right: 40px !important;
  border: none !important;
  padding-top: 40px !important;
  padding-bottom: 0 !important;
}

.data-header-modal {
  margin-left: 10px;
  font-size: 0.8em;
}

.collapse-list {
  font-size: 1em;
  font-weight: 200;
}

.style-list-collapse {
  font-size: 1.8em;
}

.btn-linkTo-prodotti {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  color: #ff00d8;
  text-decoration: underline;
  font-size: 0.8em;
  padding: 0;
  padding-left: 3px !important;
}

.selezione-prodotti {
  padding: 10px;
  border: 1px solid #ff00d8;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  word-break: break-word;
}

.selezione-prodotti-p {
  margin-bottom: 0;
  cursor: pointer;
  width: 100%;
}

.tooltiptext {
  visibility: hidden;
  width: auto;
  min-width: 160px;
  padding-left: 15px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 0.7em;
  line-height: 1.2em;
  font-weight: 300;
  background-color: #000;
  color: #fff;
  left: 110%;
  opacity: 0.8;
  top: -8px;
  text-align: left;
  border-radius: 20px;
  position: absolute;
  z-index: 1;
}

.tooltiptally {
  position: relative;
  display: inline;
}

.tooltiptally:hover span {
  visibility: visible;
}

.tooltip-i {
  fill: #ff00d8 !important;
  margin-top: -3px;
  width: 16px;
}

.img-btn-LinkTo-prodotti {
  transform: rotate(180deg);
  width: 20px;
}

.titolo-pagina-tags {
  margin-left: -15px;
}

.btn-create-order {
  width: 100%;
  box-shadow: 0;
  border: 1px solid #818181;
  opacity: 0.9;
  border-radius: 30px;
  font-weight: bold;
  font-size: 0.7em;
  height: 30px;
  background-color: white;
  margin-top: 20px;
}

.btn-create-order:hover {
  box-shadow: 0;
  border: 1px solid #ff00d8;
  opacity: 0.9;
  border-radius: 30px;
  font-weight: bold;
  font-size: 0.7em;
  height: 30px;
  color: white;
  background-color: #ff00d8;
}

.btn-create-order-active {
  width: 100%;
  box-shadow: 0;
  border: 1px solid #ff00d8;
  opacity: 0.9;
  border-radius: 30px;
  font-weight: bold;
  font-size: 0.7em;
  height: 30px;
  background-color: #ff00d8;
  margin-top: 20px;
  color: white;
}

.nopadding-btn-create-order {
  padding: 0 !important;
  margin: 0 !important;
  padding-left: 2% !important;
}

.div-prodotto-market {
  margin-top: 22px;
  padding-top: 20px !important;
  margin-right: 0;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  height: 170px;
}

.btn-add-prodotto {
  box-shadow: 0;
  border: 1px solid rgb(16, 211, 0);
  opacity: 0.9;
  width: 100%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 0.9em;
  height: 35px;
  color: white;
  background-color: rgb(16, 211, 0);
  margin-top: 10px;
}

.btn-add-prodotto:hover {
  box-shadow: 0;
  border: 1px solid rgb(16, 211, 0);
  opacity: 0.9;
  width: 100%;
  border-radius: 30px;
  font-weight: bold;
  font-size: 0.9em;
  height: 35px;
  color: rgb(16, 211, 0);
  background-color: white;
  margin-top: 10px;
}

.titolo-prodotto-market {
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 15px;
}

.valore-prodotto-market {
  font-size: 1em;
  margin-left: 15px;
}

.div-carrello-market {
  margin-top: 22px;
  padding: 20px;
  margin-right: 0;
  border: 1px solid black;
  border-radius: 5px;
  width: 100% !important;
  height: 380px;
}

.lista-prodotti {
  height: 400px;
  overflow-y: scroll;
}

.lista-prodotti-acquistati {
  height: 250px;
  padding-top: 10px;
  overflow-y: scroll;
}

.btn-acquista-prodotto {
  color: white;
  background-color: box-tally-dashboard;
  border: 1px solid #ff00d8;
}

.btn-acquista-prodotto:hover {
  color: #ff00d8;
  background-color: white;
  border: 1px solid #ff00d8;
}

.link-tally-add-prodotto {
  margin-left: 10px;
}

.btn-add-prodotto-1 {
  box-shadow: 0;
  border: none;
  opacity: 0.9;
  height: 15px;
  font-weight: bold;
  font-size: 0.9em;
  color: rgb(16, 211, 0);
  background-color: white;
  height: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.btn-add-prodotto-2 {
  width: 8px;
  margin-left: 10px;
  margin-top: -3px;
  cursor: pointer;
}

.title-input-create-tag {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1.2em;
}

.description-create-tag {
  line-height: 1.2em;
  font-size: 15px;
  color: #222222;
  font-weight: 300;
}

.box-tally-dashboard {
  text-align: center;
  border: 1px solid #707070;
  height: 100%;
  color: black;
  border-radius: 20px;
}

.box-tally-dashboard p {
  margin-bottom: 0;
}

.box-tally-dashboard:hover {
  text-align: center;
  text-decoration: none;
  border: 1px solid #ff00d8;
  background-color: #ff00d8;
  color: white;
  cursor: pointer;
  height: 100%;
  border-radius: 20px;
  fill: white !important;
}

.box-tally-dashboard:hover img {
  fill: white !important;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(115deg)
    brightness(108%) contrast(300%);
}

.box-tally-dashboard:hover p {
  text-decoration: none;
}

.box-tally-createtag {
  text-align: center;
  border: 1px solid #ff00d8;
  height: 100%;
  color: black;
  border-radius: 20px;
}

.box-tally-createtag p {
  margin-bottom: 0;
  font-size: 0.9em;
  line-height: 1.2em;
}

.box-tally-createtag:hover {
  text-align: center;
  text-decoration: none;
  border: 1px solid #ff00d8;
  background-color: #ff00d8;
  color: white;
  cursor: pointer;
  height: 100%;
  border-radius: 20px;
  fill: white !important;
}

.box-tally-createtag:hover p {
  text-decoration: none;
}

.broadcamp-tally {
  font-size: 1.2em;
  color: #bebebe;
  font-weight: 300;
  text-decoration: none;
}

.broadcamp-tally a {
  color: #bebebe;
  font-weight: 300;
  text-decoration: none;
}

.broadcamp-tally a:hover {
  color: #bebebe;
  font-weight: 300;
  text-decoration: underline;
}

.broadcamp-tally:hover {
  color: #bebebe;
}

.box-tally-createtag-active {
  text-align: center;
  border: 1px solid #ff00d8;
  background-color: #ff00d8;
  color: white;
  height: 100%;
  color: black;
  border-radius: 20px;
}

.box-tally-createtag-active p {
  margin-bottom: 0;
  color: white;
  line-height: 1.2em;
  font-size: 0.9em;
}

.box-tally-createtag-active:hover p {
  text-decoration: none;
}

.selezione-create-tag {
  font-size: 0.9em;
  color: #ff00d8;
  margin-bottom: 5px;
  margin-top: 15px;
}

.div-carrello-quantità-prodotti {
  border-radius: 10px;
  border: 1px solid rgb(16, 211, 0);
  margin-top: 10px;
}

.carrello-quantità-prodotto {
  font-size: 0.9em;
}

.carrello-div-prezzo-totale {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}

.div-pagamento {
  margin-top: 4%;
}

.div-pagamento-content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 4%;
  padding-bottom: 3%;
  margin-bottom: 2%;
  border-radius: 30px;
  min-height: 600px;
  height: auto;
  width: 100%;
  border: 1px solid #ff00d8;
}

.numero-piano {
  color: #bebebe;
  font-size: 20px;
  font-weight: bold;
}

.numero-piano-active {
  color: #ff00d8;
  font-size: 20px;
  font-weight: bold;
}

.scegli-piano-span {
  color: #ff00d8;
  font-size: 22px;
  margin-left: 12px;
}

.scegli-piano-title {
  color: #ff00d8;
  font-size: 20px;
  font-weight: bold;
}

.descrizione-scegli-piano {
  margin-top: 2%;
  line-height: 1.2em;
  font-size: 16px;
  color: #222222;
}

.scegli-piano-pricing-tally {
  color: #ff00d8;
}

.choose-plan-row {
  margin-top: 45px;
}

.scegli-piano-box {
  border: 1px solid #ff00d8;
  border-radius: 30px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 2%;
  margin-bottom: 25px;
  padding-bottom: 40px;
}

.scegli-piano-box-2 {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 1px solid #ff00d8;
  border-radius: 30px;
  margin-left: 2%;
  margin-bottom: 25px;
  padding-bottom: 40px;
}

.scegli-piano-header-box {
  background-color: #ff00d8;
  width: 100%;
  height: 20%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  text-align: center;
  padding-top: 4%;
}

.scegli-piano-header-box-title {
  color: white;
  font-size: 33px;
  font-weight: 100;
}

.scegli-piano-body-box {
  text-align: center !important;
  padding-top: 3%;
  padding-bottom: 3%;
}

.scegli-piano-body-box-title-1 {
  color: #151b26;
  font-size: 45px;
  line-height: 1em;
}

.euro-scegli-piano {
  color: #222222;
  font-size: 40px;
}

.scegli-piano-body-box-title-2 {
  font-style: italic;
  font-size: 16px;
  color: #222222;
  margin-left: 10px;
}

.radio-list {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #10d300;
  margin-top: 10px;
  margin-right: 50px;
}

.scegli-piano-list {
  font-size: 16px;
  padding-left: 6%;
  padding-right: 6%;
  margin-bottom: 3px;
}

.scegli-piano-bold {
  font-weight: bold;
}

.button-scegli-piano {
  color: #ffffff;
  background-color: #10d300;
  width: 100%;
  box-shadow: none;
  border: 1px solid #10d300;
  height: 40px;
  border-radius: 30px;
}

.container-scegli-piano-button {
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 12%;
}

.inserisci-dati-title {
  font-size: 15px;
}

.scegli-piano-dati-fatturazione-span {
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
}

.scegli-piano-dati-fatturazione-div-input {
  width: 100%;
}

.scegli-piano-dati-fatturazione-div-input {
  width: 95% !important;
  font-size: 18px;
}

.scegli-piano-dati-fatturazione-span-light {
  font-size: 18px;
  font-weight: 400;
}

.dati-fatturazione-span-input {
  margin-bottom: 4%;
  padding-left: 0 !important;
}

.button-scegli-piano-dati-fatturazione {
  width: 86%;
  margin-top: 8%;
}

.div-span-privacy-dati-fatturazione {
  padding-right: 12%;
  margin-top: 3%;
}

.span-privacy-dati-fatturazione {
  font-size: 15px;
  line-height: 1.3em;
}

.span-privacy-dati-fatturazione-active {
  color: #ff00d8;
}

.span-privacy-dati-fatturazione-active:hover {
  color: #ff00d8;
  text-decoration: underline !important;
}

.container-paypal-box {
  margin-top: 4%;
  margin-left: 3%;
}

.procedi-con-pagamento-text-left {
  padding-right: 28%;
}

.autocomplete-pagamento-div {
  padding-left: 6%;
  margin-bottom: 4%;
}

.div-autocomplete-pagamento {
  width: 90%;
}

.choose-image:hover {
  filter: brightness(50%);
  cursor: pointer;
  transition: all 1s;
  -webkit-filter: brightness(50%);
  -webkit-transition: all 0.5s;
}

.editor-tally .rdw-dropdown-wrapper {
  border-radius: 15px !important;
  border: 0;
}
.rdw-dropdown-wrapper a {
  color: black;
}
.editor-tally {
  border: 1px solid #ff00d8 !important;
  border-radius: 15px;
  padding: 15px;
  min-height: 300px !important;
}
.rdw-editor-toolbar {
  border: 0 !important;
}
.rdw-fontfamily-dropdown {
  display: none;
}
.rdw-link-modal {
  height: auto !important;
}
.rdw-link-modal label {
  color: black;
  margin-bottom: 0px;
}
.rdw-link-modal-input {
  margin-bottom: 5px !important;
  border-radius: 25px !important;
  padding: 7px !important;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.rdw-editor-wrapper {
  min-height: 200px;
  border: 1px solid lightgray;
  border-radius: 15px;
  padding: 15px;
}
.rdw-option-wrapper {
  border: 0px solid white !important;
  padding: 8px !important;
}
.rdw-inline-wrapper .rdw-option-active {
  box-shadow: none !important;
  border: 1px solid black !important;
}
