header {
    margin-top: 25px;
}
header span{
    font-size: 1.1em;
}
.myimg {
    width: 65px;
    height: 65px;
    object-fit: cover;
    margin-top: -15px;
    border-radius: 50%;
}

.informazioni-header {
    margin-top: 0%;
    line-height: 1.1em;
}

@media screen and (max-width: 1500px) and (min-width:1000px) {
    .informazioni-header {
        margin-left: 15px;
    }
}

@media screen and (min-width: 1800px) {
    .informazioni-header {
        margin-left: -25px;
    }
}

header select {
    position: absolute;
    width: fit-content;
    border: none;
    margin: 10px 50px;
}
select:focus{
    outline: none;
}