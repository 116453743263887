.centrato-verticale-div {
  margin: 0;
  position: absolute;
  left: 42%;
  top: 50%;
  width: 270px ;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: "hidden";
}
.btn-tally {
  border: 1px solid #ff00d8;
  color: #ff00d8;
  background-color: white;
  border-radius: 30px;
  opacity: 1;
  width: 100% !important;
  height: 44px;
}
.btn-tally:hover {
  color: white;
  background-color: #ff00d8;
}
.testo-tally-viola {
  background-color: white;
  color: #ff00d8;
  background-color: white;
  text-align: left;
  margin-top: 0 !important;
  background-color: white;
  margin-bottom: 0;
}
.input-tally {
  width: 100%;
}
.input-tally:focus {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ff00d8;
}

.testo-tally-grigio {
  text-align: left;
  text-decoration: underline;
  font: Light 15px/22px Be Vietnam;
  letter-spacing: 0;
  color: #222222;
  font-size: 0.8em;
  opacity: 1;
}
@media only screen and (max-width: 600px) {
  .centrato-verticale-div {
    margin: 0;
    position: absolute;
    left: 15%;
    overflow: "hidden";
  }
}
